<template>
	<div class="page-web page-home">
		<Head />
      <div style="max-width: 80%;  margin: 30px auto; ">
        <div class="news-detail">
    <!-- <h1 style="font-size: 35px; text-align: center;">{{$t('announcementtitle')}}</h1> -->
	<span v-html="nativePageContent" style="text-indent: 2em !important"></span>
    <!-- <div style="font-size: 15px; text-align: center;">发布日期: {{ detail.date }}</div> -->
      </div>
	  <div style="width: 80%; margin: 20px auto;"><img src="@/assets/img/sytp.jpg" alt="" style="width: 100%;"/></div>
	  </div>
		<Foot />
	</div>
</template>

<script>
	import Head from '@/components/Head.vue'
	import Foot from '@/components/Foot.vue'
	export default {
		name: 'Home',
		components: {
			Head,
			Foot
		},
		data() {
			return {
      		detail: {},
			nativePageContent:''
    	  };
		},
		created() {	
           this.nativePageContent= this.$t('announcement');
            },
		mounted() {
		
    },	
        methods: {
     
    } 
}	
</script>
<style scoped>
p{
	text-indent: 2em !important;
	font-size: 25 px;
}
::v-deep .typed-cursor{
	display: none !important;
}
.index-download .flex_bd{
	padding-right: 80px;
}
@media (max-width: 700px) {
	.index-download .flex_bd{
	padding-right: 0;
}
}
.content {
  /* 新闻内容区域样式 */
  white-space: pre-line; /* 保持换行 */
} 
</style>

